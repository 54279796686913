<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="app" x1="0.5" x2="0.5" y1="0.996" y2="0.022">
     <stop offset="0" stop-color="#fa233b"/>
     <stop offset="1" stop-color="#fb5c74"/>
    </linearGradient>
   </defs>
   <g transform="translate(-370 -688)">
    <rect :fill="background" height="60" transform="translate(370 688)" width="60"/>
    <g transform="translate(385 697.997)">
     <path d="M30,9.384q0-.538,0-1.075c0-.3,0-.6-.013-.905a13.128,13.128,0,0,0-.173-1.97,6.657,6.657,0,0,0-.618-1.874A6.3,6.3,0,0,0,26.439.806,6.673,6.673,0,0,0,24.567.188,13.139,13.139,0,0,0,22.6.015c-.3-.008-.6-.012-.9-.013q-.538,0-1.075,0H9.384Q8.847,0,8.309,0c-.3,0-.6.005-.905.013A13.14,13.14,0,0,0,5.433.189,6.637,6.637,0,0,0,3.561.807,6.3,6.3,0,0,0,.806,3.561,6.657,6.657,0,0,0,.188,5.435a13.138,13.138,0,0,0-.173,1.97c-.008.3-.012.6-.013.905q0,.536,0,1.074V20.615q0,.538,0,1.075c0,.3.005.6.013.9a13.128,13.128,0,0,0,.173,1.97,6.657,6.657,0,0,0,.618,1.874,6.3,6.3,0,0,0,2.755,2.754,6.673,6.673,0,0,0,1.872.618,13.139,13.139,0,0,0,1.971.173c.3.008.6.012.905.013.358,0,.717,0,1.075,0H20.615q.538,0,1.075,0c.3,0,.6,0,.9-.013a13.139,13.139,0,0,0,1.971-.173,6.637,6.637,0,0,0,1.872-.618,6.3,6.3,0,0,0,2.755-2.754,6.657,6.657,0,0,0,.618-1.874,13.138,13.138,0,0,0,.173-1.97c.008-.3.012-.6.013-.9,0-.358,0-.717,0-1.075V9.384Z" fill="url(#app)" fill-rule="evenodd"/>
     <g transform="translate(5.929 4.58)">
      <path d="M86.432,54.96c-.073.007-.717.121-.794.137l-8.917,1.8h0a1.243,1.243,0,0,0-.556.25.907.907,0,0,0-.3.579,2.033,2.033,0,0,0-.02.3v11.16a1.08,1.08,0,0,1-.2.73,1.011,1.011,0,0,1-.651.332l-.583.118a10.169,10.169,0,0,0-1.65.417,2.59,2.59,0,0,0-.973.626,2.373,2.373,0,0,0,.118,3.35,2.246,2.246,0,0,0,1.082.555,3.984,3.984,0,0,0,1.609-.082,3.274,3.274,0,0,0,1.042-.384,2.617,2.617,0,0,0,.821-.759,2.773,2.773,0,0,0,.437-1.029,5.169,5.169,0,0,0,.1-1.105V62.277c0-.518.147-.655.565-.757,0,0,7.412-1.5,7.757-1.562.482-.093.71.045.71.551v6.608a1.1,1.1,0,0,1-.181.743,1.011,1.011,0,0,1-.651.332l-.583.118a10.168,10.168,0,0,0-1.65.417,2.59,2.59,0,0,0-.973.626,2.332,2.332,0,0,0,.118,3.35,2.247,2.247,0,0,0,1.083.55,4,4,0,0,0,1.609-.082,3.263,3.263,0,0,0,1.042-.379,2.617,2.617,0,0,0,.821-.759A2.773,2.773,0,0,0,87.1,71a5.15,5.15,0,0,0,.083-1.105V55.748C87.186,55.235,86.915,54.918,86.432,54.96Z" fill="#fff" fill-rule="evenodd" transform="translate(-71.154 -54.956)"/>
     </g>
    </g>
    <text font-family="HelveticaNeue, Helvetica Neue" font-size="8" transform="translate(378 740)">
     <tspan x="0" y="0">
      Apple Music
     </tspan>
    </text>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>